<template>
  <span
    :class="[
      'bsc-mini-cart',
      {
        'bsc-mini-cart_active': show
      }
    ]"
  >
    <div
      class="bsc-mini-cart__trigger j-bsc-mini-cart__trigger"
      @mouseenter="onMouseenter"
    >
      <slot name="reference"></slot>
    </div>
    <div
      v-show="show"
      class="bsc-mini-cart__container bsc-header-dropdown j-bsc-mini-cart__container"
      @click.stop.prevent
    >
      <div
        class="bsc-mini-cart__content j-bsc-mini-cart__content"
        @mouseenter="onMouseenterContent"
      >
        <MiniCartLoading v-if="loading" />
        <template v-else-if="carts.length">
          <div class="bsc-mini-cart-body">
            <FullCoupon />
            <MiniCartList :show-new-user-tag="showNewUserTag" />
          </div>
          <MiniCartFooter
            :cart-info="cartInfo"
            :valid-mall-carts="validMallCarts"
            :lang="appConfigs.$envs.lang"
            :css-right="appConfigs.$envs.cssRight"
            :cart-item-map="cartItemMap"
            :language="language"
            :top-offset-target="topOffsetTarget"
          >
            <template #top>
              <PlatformShipping
                :mall-code="singleMallCode"
                :mall-shipping-info="mallShippingInfo"
                :show-new-free-shipping="showNewFreeShipping"
                :language="language"
              />
            </template>
          </MiniCartFooter>
        </template>
        <MiniCartEmpty
          v-else
          :language="language"
          :is-login="isLogin"
        />
      </div>
      <div
        v-show="show"
        class="bsc-mini-cart__overlay"
        @click.stop.prevent
        @mouseenter.stop.prevent="onMouseenterOverlay"
      >
      </div>
    </div>
  </span>
</template>

<script name="BMiniCart" setup lang="ts">
import { ref, computed, watch, provide, nextTick, onMounted } from 'vue'
import MiniCartLoading from './components/mini-cart-loading.vue'
import MiniCartEmpty from './components/mini-cart-empty.vue'
import FullCoupon from './components/full-coupon.vue'
import MiniCartList from './components/mini-cart-list/mini-cart-list.vue'
import MiniCartFooter from './components/mini-cart-footer.vue'
import PlatformShipping from './components/platform-shipping.vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { C_MiniCart } from '../../types'
import { useAnalysis } from '../../common/analysisSource'
import { AUTO_HIDE_DELAY } from './constants'
import useMiniCart from './hooks/useMiniCart'

const appConfigs = useAppConfigs()
const props = withDefaults(defineProps<C_MiniCart.IProps>(), {
  hideAfter: 100,
  test: false,
})
const emit = defineEmits(['beforeClickModifyCheckbox', 'afterClickModifyCheckbox', 'update', 'mounted', 'clickViewCart'])
// 数据源: END

const { show, toggle, loading, language, mallCartInfo, cartInfo, carts, mallShippingInfo, isPlatform, maxHeight, cartItemMap, updateMaxHeight, soldoutItems, priceMode, getCartInfo, cartUpdate, cartDelete, modifyCartCheckStatus, validMallCarts, updateOverlayOffset, overlayOffset, hideMiniCart, getPromotionLink, showNewUserTag, zIndex, updatePlaceholderStyles, placeholderStyles } = useMiniCart(props, emit)
provide('mallCartInfo', mallCartInfo)
provide('soldoutItems', soldoutItems)
provide('isPlatform', isPlatform)
provide('language', language)
provide('cartInfo', cartInfo)
provide('carts', carts)
provide('priceMode', priceMode)
provide('modifyCartCheckStatus', modifyCartCheckStatus)
provide('cartUpdate', cartUpdate)
provide('cartDelete', cartDelete)
provide('validMallCarts', validMallCarts)
provide('getPromotionLink', getPromotionLink)
provide('test', props.test)


// 埋点源:
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
// 埋点源: END

const autoHideing = ref(false)
let autoHideTimer
const autoHideMiniCart = () => {
  autoHideTimer = setTimeout(() => {
    hideMiniCart()
    clearTimeout(autoHideTimer)
    autoHideTimer = null
    autoHideing.value = false
  }, AUTO_HIDE_DELAY)
}

interface IShowMiniCartOptions {
  autoHide?: boolean
  sceneFrom?: string
}
const showMiniCart = (options?: IShowMiniCartOptions) => {
  const { autoHide = false, sceneFrom = '' } = options || {}
  toggle(true)
  nextTick(() => {
    updateOverlayOffset()
    updatePlaceholderStyles()
  })
  if (carts.value.length) {
    nextTick(updateMaxHeight)
  }
  if (autoHide) {
    autoHideMiniCart()
  }
  analysisInstance?.triggerNotice({
    id: 'expose_addbagpop.comp_mini-cart',
    data: {
      scene_from: sceneFrom,
    },
  })
}
const onMouseenter = () => {
  autoHideing.value = false
  if (autoHideTimer) {
    clearTimeout(autoHideTimer)
    autoHideTimer = null
  }
  if (show.value) return
  showMiniCart({ sceneFrom: 'entrance_hover' })
}
const onMouseenterContent = () => {
  autoHideing.value = false
  if (autoHideTimer) {
    clearTimeout(autoHideTimer)
    autoHideTimer = null
  }
}
const onMouseenterOverlay = () => {
  if (autoHideing.value) return
  hideMiniCart()
}
const onBeforeClickModifyCheckbox = (checked: number, type: string) => {
  emit('beforeClickModifyCheckbox', checked, type)
}
const onAfterClickModifyCheckbox = (checked: number, type: string, code: number) => {
  emit('afterClickModifyCheckbox', checked, type, code)
}
const onClickViewCart = () => {
  emit('clickViewCart')
}
provide('onBeforeClickModifyCheckbox', onBeforeClickModifyCheckbox)
provide('onAfterClickModifyCheckbox', onAfterClickModifyCheckbox)
provide('onClickViewCart', onClickViewCart)

const singleMallCode = computed(() => {
  if (!isPlatform.value) {
    return carts.value.length ? carts.value[0].mall_code || '' : ''
  }
  return ''
})
const showNewFreeShipping = computed(() => {
  return !isPlatform.value ? true : false
})

import { injectCommonHeaderContext, injectCommonHeaderEventProxy } from '@shein-aidc/bs-common-header-laptop'
const commonHeaderContext = injectCommonHeaderContext()
watch(() => commonHeaderContext?.isShow?.value, () => {
  setTimeout(() => {
    updateMaxHeight()
    updateOverlayOffset()
    updatePlaceholderStyles()
  }, 300)
})

const isLogin = ref(appConfigs.$getters.isLogin)
const commonHeaderEventProxy = injectCommonHeaderEventProxy()
interface IUpdateCartOptions {
  showMiniCart: boolean
  showMiniCartOptions: IShowMiniCartOptions
  miniCartRes?: any
  miniCartCallback?: Function
}
commonHeaderEventProxy?.subscribe('mini-cart', {
  async updateCart(data: IUpdateCartOptions) {
    const res: any = await getCartInfo({
      ...data,
      __isExternal__: true,
    })
    if (res?.code == '0') {
      if (data?.showMiniCart) {
        autoHideing.value = true
        showMiniCart(data.showMiniCartOptions)
      }
      data?.miniCartCallback?.(res)
    }

    isLogin.value = appConfigs.$getters.isLogin
  },
  isLogin() {
    isLogin.value = appConfigs.$getters.isLogin
  },
})

const mallCartBiData = computed(() => mallCartInfo.value?.mallCartBiData)
const storeTrendLogoReport = () => {
  const trendStoreCodeList = mallCartBiData.value.trendStoreCodeList ?? []
  if (trendStoreCodeList.length) {
    analysisInstance?.triggerNotice({
      id: 'expose_scenesabt.comp_mini-cart',
      data: {
        scenes: 'store_trends_logo',
        type: trendStoreCodeList.join(','),
      },
    })
  }
}
watch([
  () => mallCartBiData.value,
  () => show.value,
], ([data, show]) => {
  if (show && data) {
    storeTrendLogoReport()
  }
})


onMounted(() => {
  emit('mounted')
})
</script>

<style lang="less">
.bsc-mini-cart {
  display: inline-block;
  &.bsc-mini-cart_active {
    z-index: v-bind(zIndex);
  }
  .bsc-mini-cart__trigger {
    position: relative;
    z-index: 2;
  }
  .bsc-mini-cart__container {
    position: absolute;
    top: 100%;
    left: auto;
    right: 25px;
    font-family: Muli,Arial,Helvetica,sans-serif;
    white-space: normal;
    text-wrap: normal;
    &::before {
      content: '';
      position: absolute;
      top: v-bind('placeholderStyles.top');
      left: v-bind('placeholderStyles.left');
      height: v-bind('placeholderStyles.height');
      width: v-bind('placeholderStyles.width');
      z-index: 2;
    }
    .bsc-mini-cart__content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 400px;
      max-height: v-bind(maxHeight);
      background-color: #f6f6f6;
      line-height: 1.15;
      cursor: default;
      box-shadow:0px 6px 6px 0px rgba(0,0,0,0.08);
      z-index: 2;
      transition: max-height 0.1s;
    }
    .bsc-mini-cart-body {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      overscroll-behavior: contain;
      &::-webkit-scrollbar {
        background: #fff;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #e0e0e0;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f7f8fa;
      }
    }
  }

  .bsc-mini-cart__overlay {
    position: absolute;
    top: v-bind('overlayOffset.top');
    left: v-bind('overlayOffset.left');
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
}
</style>
