<template>
  <div
    v-if="isShowRobot"
    v-tap="{ id: 'click_support.comp_header-robot' }"
    class="bs-c-header-robot__wrap"
    @mouseenter="isShowPop = true"
    @mouseleave="isShowPop = false"
    @click="navTo"
  >
    <Supports />

    <!-- 小红点 -->
    <em
      v-if="isShowUnRead"
      class="bs-c-header-robot__unread"
    ></em>

    <!-- popup -->
    <div
      v-if="isShowPop"
      class="bs-c-header-robot__wrap-dropdown bsc-header-dropdown"
    >
      <Supports />
      <div class="bs-c-header-robot__customer-box">
        <p class="bs-c-header-robot__customer-subtitle">
          {{ robotInfo.contact_agent_title }}
        </p>
        <p class="bs-c-header-robot__customer-des">
          {{ robotInfo.contact_agent_content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script name="BHeaderRobot" setup lang="ts">
import { ref, onBeforeMount, computed } from 'vue'
import Supports from './icons/Supports.vue'
import { getOrderJumpInfo, robotLinkCommon } from './jumpToRobot'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_HeaderRobot, DS_HeaderRobot } from '../../types'

const appConfigs = useAppConfigs()
const props = defineProps<{
  fsData?: DS_HeaderRobot.FS_DATA
  dataSource?: DS_HeaderRobot.DataSource
  analysisSource?: AS_HeaderRobot.AnalysisSource
}>()
const apis = getSource({ appConfigs, dataSource: props.dataSource })
// 数据源：END

const isShowRobot = ref<Boolean>(false)
const isShowPop = ref<Boolean>(false)
const isShowUnRead = ref<Boolean>(false)
const robotInfo = ref<any>({})
const SaPageInfo = appConfigs?.$getters?.SaPageInfo || {}
const isRefeshUnread = appConfigs?.$getters?.isLogin || false
const emit = defineEmits<{
  (e: 'unLoginJump', robotInfo: any): void
  (e: 'onLoaded', isShowPop: any): void
}>()

onBeforeMount(async () => {
  const [unRead, chanRes] = await Promise.all([
    isRefeshUnread ? apis.getChatUnreadCount() : Promise.resolve({}),
    apis.getRobotCustomerChan(),
  ])
  const entrance = chanRes?.info?.entrance || []
  const { tk_unread_count = 0, total_unread_count = 0 } = unRead?.info || {}
  isShowUnRead.value = total_unread_count || tk_unread_count
  const hasConfig = entrance?.find(_ => _?.position == 'navigationBar') || null
  if (hasConfig && hasConfig.open === 0) {
    isShowRobot.value = false
  } else {
    isShowRobot.value = true
  }
  robotInfo.value = chanRes?.info?.['v2'] || {}
  emit('onLoaded', isShowRobot)
})

const navTo = () => {
  if (appConfigs?.$getters?.isLogin) {
    !isRefeshUnread && apis.getChatUnreadCount().then((unRead) => {
      const { tk_unread_count = 0, total_unread_count = 0 } = unRead?.info || {}
      isShowUnRead.value = total_unread_count || tk_unread_count
    })
    getOrderJumpInfo(SaPageInfo)
  } else {
    const redirectionUrl = robotLinkCommon(SaPageInfo)
    // 登录页直接打开弹窗
    if (SaPageInfo?.page_name == 'page_login') {
      emit('unLoginJump', robotInfo.value)
      return
    }
    location.href = `/user/auth/login?redirection=${encodeURIComponent(redirectionUrl.uri)}&robotScene=${redirectionUrl.name}_${robotInfo.value?.no_login_second_confirm ? 1 : 0}`
  }
}

// 埋点源: 
import { useAnalysis } from '../../common/analysisSource'
import { provide } from 'vue'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
const { vTap } = analysisInstance
// 埋点源: END
</script>

<style lang="less">
.bs-c-header-robot {
  &__wrap {
    font-size: 14px;
    line-height: 1.15;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
  }
  &__wrap-dropdown {
    position: absolute;
    right: -26px;
    background: #fff;
    z-index: 600;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .08);
    transition: transform .3s;
    min-width: 236px;
    padding: 18px;
    white-space: nowrap;
    display: flex;
  }
  &__customer-box {
    margin-left: 10px;
  }
  &__customer-subtitle {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: 18px;
  }
  &__customer-des {
    font-size: 12px;
    margin-top: 4px;
    color: #767676;
    line-height: 17px;
  }
  &__unread {
    width: 6px;
    height: 6px;
    background: #fe3b30;
    position: absolute;
    right: 0;
    top: -4px;
    border-radius: 50%;
  }
}
</style>
