<template>
  <div
    v-cloak
    class="bsc-user-dropdown"
    :class="{
      'nologin-user-dropdown': !isLogin,
    }"
    @mouseenter="showUserDropDown"
    @mouseleave="hideUserDropDown"
    @keyup.esc="adaClose"
  >
    <a
      v-for="(nav, index) in navArrs"
      :key="nav.id"
      href="javascript:;"
      :class="nav.class"
      @click="toLink({ link: nav.link, id: nav.id, index, primeType: 1 })"
    >
      <div
        v-if="nav.id === 'login' && isLogin"
        class="header-user-info"
      >
        <div class="side">
          <span class="name">{{ htmlDecode({ text: userNickname }) }}</span>
          <div
            :class="{'icon-wrapper-wrap': isPrime && isShowExtraIcon}"
            class="icon-wrapper"
          >
            <span
              class="vip-panel"
              @click.stop="
                toLink({
                  link: '/user/sheinvip',
                  id: 'vip',
                })
              "
            >
              <VipMedal
                :alert-text="language.SHEIN_KEY_PC_14581"
                :cssRight="cssRight"
                :host="host"
                :medal-url="userLevelInfo?.level_image?.url"
                size="S"
              />
            </span>
            <span
              v-if="isPrime"
              class="prime-panel"
              @click.stop="
                toLink({
                  link: '/user/prime?productsource=topsite',
                  id: 'prime',
                  primeType: 2,
                })
              "
            >
              <ClubBadge :cssRight="cssRight" />
            </span>
            <div
              v-if="isShowExtraIcon"
              :class="{'shein-extra_badge-wrap': isPrime && isShowExtraIcon}"
              class="shein-extra_badge"
            ></div>
          </div>
        </div>
      </div>
      <template v-else-if="nav.title">
        <em class="first-letter-upper">{{ htmlDecode({ text: nav.title }) }}</em>
      </template>
      <span
        v-if="nav.id === 'message' && (unReadNum || isMsgActivityAndPromoOnly)"
        class="message-nopenum"
        :class="{
          'msg-activity-only': !unReadNum && isMsgActivityAndPromoOnly,
        }"
      >
        <template v-if="unReadNum">
          {{ badgeNum_filter(unReadNum) }}
        </template>
      </span>
      <span
        v-if="nav.id === 'coupon' && showCouponTips"
        class="message-nopenum msg-activity-only"
      >
      </span>
      <span
        v-if="nav.id === 'points' && showPointsTips"
        class="message-nopenum msg-activity-only"
      >
      </span>
    </a>
  </div>
  <ClientOnly>
    <Teleport to="body">
      <LogoutRemberModal
        ref="logoutRemberModalRef"
        :lang-text="language"
      />
    </Teleport>
  </ClientOnly>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick, inject, watch } from 'vue'
import {
  htmlDecode,
  removeSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { injectCommonHeaderEventProxy } from '@shein-aidc/bs-common-header-laptop'
import { setGoogleOneTapCatch, clearVisitConfrim } from './common/login.js'
import { removeAuthUserInfo } from './common/auth.js'
import { useAccountAnalysis } from './common/useAnalysis.js'

import { usePointOrCoupon } from './common/usePersonalInfo.js'

import LogoutRemberModal from './LogoutRemberModal.vue'
import VipMedal from './VipMedal.vue'
import ClubBadge from './ClubBadge.vue'


const props = defineProps({
  accountManager: {
    type: String,
    default: '',
  },
  isMsgActivityAndPromoOnly: {
    type: Boolean,
    default: false,
  },
  unReadNum: {
    type: Number,
    default: 0,
  },
  isUserIconClick: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'showDropdown',
  'hideDropdown',
  'changeActivityAndPromoOnly',
  'changeCouponOrPointsData',
  'initCouponOrPointsNoticeDone',
  'changeUnreadNum',
])

const envs = inject('envs')
const apis = inject('apis')
const getters = inject('getters')
const triggerNotice = inject('triggerNotice')
const CommonHeaderEventProxyEventProxy = injectCommonHeaderEventProxy()

const { cssRight = false, host = '', lang, siteUID } = envs || {}

const { exposeSwitchAccount, clickSwitchAccount } = useAccountAnalysis(triggerNotice)

const language = ref({})
const logoutRemberModalRef = ref(null)
const showRedPoint = ref(false)

const showRecentlyViewAbtInfo = ref({})
const unReadNum = ref(props.unReadNum)
const isMsgActivityAndPromoOnly = ref(props.isMsgActivityAndPromoOnly)
const isManualUpdate = ref(false) // 手动更新用户信息标志

const isLogin = computed(() => getters.isLogin || isManualUpdate.value)
const memberId = computed(() => getters.memberId)

const {
  isShowPrime,
  isPrime,
  isShowExtra,
  isShowExtraIcon,
  userNickname,
  userLevelInfo,
  showCouponTips,
  showPointsTips,
  saverBuriedInfo,
  pointsOrCouponJumpEvt,
  getCouponOrPointsNotice,
  fetchUserInfo,
  } = usePointOrCoupon({ isLogin: isLogin.value, memberId: memberId.value, lang, SiteUID: siteUID, apis })

const logoutStyle = computed(() => {
  if(props.accountManager == 'on') {
    return 'header-menu-signout'
  }
  return 'header-menu-logout'
})

const navArrs = computed(() => {
  const arr = [
    {
      id: 'login',
      title: language.value.SHEIN_KEY_PC_15740,
      link: isLogin.value ? '/user/index' : '/user/auth/login?direction=nav',
      class: ['header-username'],
    },
    {
      id: 'prime',
      isShow: isShowPrime.value,
      title: language.value.SHEIN_KEY_PC_22937,
      link: '/user/prime?productsource=topsite',
    },
    {
      id: 'shein_saver',
      isShow: isShowExtra.value,
      title: language.value.SHEIN_KEY_PC_26939,
      link: '/user/sheinsaver?productsource=topsite',
    },
    {
      id: 'accountSetting',
      isShow: isLogin.value,
      title: language.value.SHEIN_KEY_PC_16195,
      link: '/user/account_setting',
      class: [],
    },
    {
      id: 'ordersList',
      title: language.value.SHEIN_KEY_PC_15755,
      link: '/user/orders/list',
      class: [],
    },
    {
      id: 'message',
      title: language.value?.SHEIN_KEY_PC_16007?.toLowerCase(),
      link: '/user/message',
      class: [],
    },
    {
      id: 'coupon',
      title: language.value.SHEIN_KEY_PC_15952,
      link: '/user/coupon',
      class: [],
    },
    {
      id: 'points',
      title: language.value.SHEIN_KEY_PC_15757,
      link: '/user/points',
      class: [],
    },
    {
      id: 'recentlyView',
      title: language.value.SHEIN_KEY_PC_14565,
      link: '/user/recently_view',
      class: [],
    },
    {
      id: 'index',
      title: language.value.SHEIN_KEY_PC_17342,
      link: '/user/index',
      class: [],
    },
    {
      id: 'switchAccount',
      isShow: isLogin.value && props.accountManager == 'on',
      title: language.value.SHEIN_KEY_PC_25875,
      link: '',
      class: ['header-menu-switchAccount'],
    },
    {
      id: 'logout',
      isShow: isLogin.value,
      title: language.value.SHEIN_KEY_PC_15739,
      link: '/user/auth/logout',
      class: [logoutStyle.value],
    },
  ]

  return arr.filter((item) => !Reflect.has(item, 'isShow') || item.isShow)
})

watch(() => props.unReadNum, (val) => {
  unReadNum.value = val
})

watch(() => props.isMsgActivityAndPromoOnly, (val) => {
  isMsgActivityAndPromoOnly.value = val
})

watch(() => props.isUserIconClick, (val) => {
  if (val) {
    // 点击用户icon后，隐藏红点
    showRedPoint.value = false
    setSessionStorage({ key: 'bscIsCheckedAccountTips', value: '1' })
  }
})

const showUserDropDown = () => {
  emits('showDropdown')
}

const hideUserDropDown = () => {
  emits('hideDropdown')
}

const badgeNum_filter = (num) => {
  if (!num) {
    return 0
  }
  return Number(num) > 99 ? '99+' : num
}

const fetchBffLanguage = async () => {
  const { info } = await apis.fetchBffLanguage()
  language.value = info?.result || {}
}

const toLink = ({ link, id, index, primeType }) => {
  const mapId = {
    login: 'click_topsite_self_image',
    accountSetting: 'click_topsite_my_profile',
    ordersList: 'click_topsite_my_orders',
    coupon: 'click_topsite_my_coupons',
    points: 'click_topsite_my_points',
    index: 'click_topsite_more_service',
    vip: 'click_top_site_vip',
  }

  switch (id) {
    case 'message':
      {
        triggerNotice({
          id: 'click_news',
          data: {
            unread_amount: unReadNum.value || 0,
          },
        })
      }
      break
    case 'recentlyView':
      triggerNotice({
        id: 'click_topsite_recently_viewed',
        data: {
          saAbt: showRecentlyViewAbtInfo.value?.sa || '',
        },
      })
      break
    case 'prime':
     triggerNotice({
        id: 'click_top_site_club',
        data: {
          click_type: primeType,
        },
      })
      break
    case 'shein_saver':
      triggerNotice({
       id: 'click_saver',
        data: {
          activity_from: 'top_menu',
          is_club_show: saverBuriedInfo.value?.is_club_show,
          saver_status: saverBuriedInfo.value?.save_status || saverBuriedInfo.value?.saver_status,
        },
      })
      break
    default:
      triggerNotice({
        id: mapId[id],
      })
  }

  if (!['vip', 'prime'].includes(id)) {
    triggerNotice({
      id: 'click_top_site_account',
      data: {
        account_content:
          index + '`' + link.substring(link.lastIndexOf('/') + 1),
        abtest: {},
      },
    })
  }
  nextTick(() => {
    pointsOrCouponJumpEvt(id, 1, isManualUpdate.value)
  })
  if(id == 'switchAccount') {
    clickSwitchAccount()
    window.SHEIN_LOGIN && window.SHEIN_LOGIN.show({
      bi: 'switch_account',
      isSwitchAccount: true,
      cb: () => {
        window.location.reload()
      },
    })
    return
  }
  if(id == 'logout') {
    try {
      if(props.accountManager == 'on'){
        logoutRemberModalRef.value.handleShowRememberModal()
        return
      }
    } catch (err) {
      console.error(err + '')
    }
    setGoogleOneTapCatch()
    removeAuthUserInfo()
  }
  window.location.href =
    link.indexOf('?') > -1 ? `${link}&from=navTop` : `${link}?from=navTop`

  // 点击消息后，隐藏用户icon上的红点
  if (id === 'message') {
    showRedPoint.value = false
    setSessionStorage({ key: 'bscIsCheckedAccountTips', value: '1' })
  }
}

const handleUserInfo = async () => {
  try {
    const userNameIconDom = document.querySelector('.j-header-username-icon')
    if (isLogin.value) {
      if (userNameIconDom) userNameIconDom.href = '/user/index'
      if (typeof fastwish != 'undefined') {
        window.fastwish.reAddwish()
      }
      // 登陆成功，去除访客模式状态位，同时设置机器人位置
      clearVisitConfrim()
      getAccounInfotAbts()
    } else {
      removeSessionStorage('bscIsCheckedAccountTips')

      let loginURL = '/user/auth/login?direction=nav'
      if (
        window.PageGroupOverview === '商品详情页' ||
        window.PageGroupOverview === '列表页'
      ) {
        const _search =
          document.location.search.indexOf('?') === 0
            ? document.location.search + '&_prot=1'
            : '?_prot=1'
        loginURL =
          '/user/auth/login?redirection=' +
          encodeURIComponent(document.location.pathname + _search)
      }
      if (userNameIconDom) userNameIconDom.href = loginURL
    }
  } catch (error) {
    console.error(error + '')
  }

  if(isLogin.value && props.accountManager == 'on'){
    exposeSwitchAccount()
  }

}


const getMsgUnreadCountEvt = async () => {
  // 非站内信页面 调用未读消息
  if (location.href.includes('user/message')) return
  const { code, info } = await apis.fetchUnreadMsg()
  if (code == '0' && info) {
    const orderNum = Number(info?.order?.num || 0)
    const newsNum = Number(info?.news?.num || 0)
    const activityNum = Number(info?.activity?.num || 0)
    const promoNum = Number(info?.promo?.num || 0)
    // promo跟activity通过开关控制
    const activityShow = !!Number(info?.activity?.is_show || 0)
    const promoShow = !!Number(info?.promo?.is_show || 0)
    // 站内信红点展示
    if (orderNum + newsNum) {
      unReadNum.value = orderNum + newsNum
      emits('changeUnreadNum', orderNum + newsNum)
      if(!getSessionStorage('bscIsCheckedAccountTips')) {
        showRedPoint.value = true
      }
    }
    // 是否有promo跟activity消息
    if ((promoShow && promoNum) || (activityShow && activityNum)) {
      isMsgActivityAndPromoOnly.value = true
      emits('changeActivityAndPromoOnly', true)
    }
  }
}

const getAccounInfotAbts = async () => {
  getCouponOrPointsNotice()
}

const adaClose = () => {
  const userDropdownDom = document.querySelector('.bsc-user-dropdown')
  if (userDropdownDom) {
    userDropdownDom.classList.remove('focus-user-icon')
    userDropdownDom.classList.add('focus-user-icon1')
  }
}

// 快速注册、弹窗登录成功后，更新用户信息
const updateUserInfo = () => {
  isManualUpdate.value = true
  fetchUserInfo(true)
  handleUserInfo()
  getMsgUnreadCountEvt()
}

onMounted(async () => {
  handleUserInfo()
  getMsgUnreadCountEvt()
  fetchBffLanguage()

  // 由于主站在 快速注册、弹窗登录 成功后，没有触发页面刷新，所以需要手动更新用户信息
  CommonHeaderEventProxyEventProxy?.subscribe('updateUserInfo', {
    // 主站触发登录事件
    isLogin() {
      updateUserInfo()
    },
  })
})

onUnmounted(() => {
  CommonHeaderEventProxyEventProxy?.describe('updateUserInfo')
})

defineExpose({
  showRedPoint,
})

</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
 .bsc-user-dropdown {
  &.nologin-user-dropdown {
    padding-bottom: 10px;
  }
  a {
    position: relative;
    display: block;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
    font-size: 12px;
    line-height: 30px;
    text-decoration: none;
    &:hover {
      color: #222;
    }
    .ticket-nopenum,
    .message-nopenum {
      display: inline-block;
      min-width: 14px;
      height: 14px;
      line-height: 14px;
      padding: 0 4px;
      margin-left: 3px;
      font-size: 12px;
      color: #fff;
      background: #f86262;
      text-align: center;
      box-sizing: content-box;
      border: 1px solid #f86262;
      border-radius: 8px;
      box-sizing: border-box;
      &.msg-activity-only {
        padding: 0;
        min-width: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 0;
        background: #ff411c;
      }
    }
    .ticket-nopenum {
      min-width: 16px;
      height: 16px;
      line-height: 1.2;
    }
    .profile-nopenum {
      color: #f86262;
      margin-left: 3px;
    }
    &.header-username {
      display: flex;
      align-items: center;
      max-width: 160px;
      line-height: 45px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: bold;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      &:hover {
        color: rgba(0, 0, 0, 1);
      }
      .user-name-text {
        display: inline-block;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      em {
        flex: auto;
      }
    }
    &.header-menu-switchAccount {
      padding-top: 5px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.header-menu-signout {
      padding-bottom: 5px;
    }
    &.header-menu-logout{
      line-height: 42px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    .survey-nopenum {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #f86262;
    }
  }
  .header-username {
    .header-user-info {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .avatar {
        margin-right: 5px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      .side {
        display: inline-flex;
        flex-direction: column;
        .icon-wrapper {
          display: flex;
          align-items: center;
        }
        .icon-wrapper-wrap {
          flex-wrap: wrap;
          min-width: 160px;
        }
        .name {
          margin-bottom: 3px;
          line-height: 1.15;
          font-size: 12px;
          white-space: break-spaces;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .vip-panel {
          align-self: flex-start;
          line-height: 1.15;
          margin-right: 8px
        }
        .prime-panel {
          align-self: flex-start;
          line-height: 1.15;
        }
        .shein-extra_badge {
          width: 80px;
          height: 16px;
          background: url('https://img.ltwebstatic.com/images3_ccc/2024/09/19/a1/1726736097414854aaff69053935b7184891f5f7a3.webp') no-repeat center;
          background-size: contain;
          margin-left: 8px;
        }
        .shein-extra_badge-wrap {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }
  }
  .first-letter-upper {
    text-transform: capitalize;
  }
}
</style>
