import { computed, inject, ref, getCurrentInstance, onMounted } from 'vue'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'

export function usePointOrCoupon({ isLogin, memberId, lang, SiteUID, apis }) {

  const USER_CARD_TYPE = {
    prime: 1, // 会员卡
    saver: 2, // 超省卡
  }
  const BURIED_TYPE = {
    expose: 1, // 曝光
    click: 2, // 点击
  }
  const triggerNotice = inject('triggerNotice')
  const getCouponOrPointsData = inject('getCouponOrPointsData')
  const internalInstance = getCurrentInstance()

  const emits = internalInstance.emit

  const isPrime = ref(false) // 是否是会员
  const isShowPrime = ref(false)
  const isShowExtra = ref(false)
  const isShowExtraIcon = ref(false)
  const userNickname = ref('')
  const userLevelInfo = ref({})
  const expirePointCouponInfo = ref({})
  const saverBuriedInfo = ref({})

  const couponNum = computed(() => {
    // 字符串文案，从里面提取出数字
    const expireCouponTotal = expirePointCouponInfo.value?.expireCouponTotal || ''
    const num = expireCouponTotal.match(/\d+/g)
    return num ? Number(num[0]) : 0
  })

  const pointsNum = computed(() => {
    const expirePointTotal = expirePointCouponInfo.value?.expirePointTotal || ''
    const num = expirePointTotal.match(/\d+/g)
    return num ? Number(num[0]) : 0
  })

  const showCouponTips = computed(() => {
    const { isOpenCoupon, isClickCoupon } = getCouponOrPointsData() || {}
    const result = isOpenCoupon && !isClickCoupon && couponNum.value >= 1
    if (result) {
      triggerNotice({
        id: 'expose_coupon_reminder',
        data: {
          reminder_type: 1,
        },
      })
    }
    return result
  })

  const showPointsTips = computed(() => {
    const { isOpenPoints, isClickPoints } = getCouponOrPointsData() || {}
    const result = isOpenPoints && !isClickPoints && pointsNum.value >= 50
    if (result) {
      triggerNotice({
        id: 'expose_point_reminder',
        data: {
          reminder_type: 1,
        },
      })
    }
    return result
  })
  // isManualCall 是否是手动调用
  const fetchUserInfo = async (isManualCall = false) => {
    if (!isLogin && !isManualCall) return
    const { info } = await apis.fetchUserInfo()
    expirePointCouponInfo.value = info?.expirePointCouponInfo || {}
    userLevelInfo.value = info?.userLevelInfo || {}
    userNickname.value = info?.personalInfo?.nickname || info?.personalInfo?.email || ''

    const memberCardList = info?.personalCenterEntranceInfo?.member_card_list || []
    isShowPrime.value = memberCardList.some((item) => item.cardType === USER_CARD_TYPE.prime)
    isPrime.value = memberCardList.some((item) => item.cardType === USER_CARD_TYPE.prime && item?.isPaid === 1)
    const extraInfo = memberCardList.find((item) => item.cardType === USER_CARD_TYPE.saver) || {}
    handleExtraInfo(extraInfo)
  }

  // 处理超省卡
  const handleExtraInfo = async (extraInfo) => {
    const keys = Object.keys(extraInfo || {})
    isShowExtra.value = keys.length > 0
    isShowExtraIcon.value = extraInfo.isPaid === 1
    if (isShowExtra.value) {
      const buriedInfo = extraInfo?.buriedObject?.find((item) => item.buriedKey === 'saver' && item.buriedType === BURIED_TYPE.expose)?.defaultParams || {}
      saverBuriedInfo.value = buriedInfo
      triggerNotice({
        id: 'expose_saver',
        data: {
          activity_from: 'top_menu',
          is_club_show: buriedInfo.is_club_show,
          saver_status: buriedInfo.save_status || buriedInfo?.saver_status,
        },
      })
    }
  }

  const handleEventEmits = (data, isClick = false) => {
    emits('changeCouponOrPointsData', { ...data })
    if (!isClick) {
      emits('initCouponOrPointsNoticeDone')
    }
  }

  const getCouponOrPointsNotice = async () => {
    // 15分钟获取一次接口数据
    const member_id = memberId || ''
    const couponOrPointsData = parseCookieData(getLocalStorage(`${member_id}_${SiteUID}_${lang}_couponOrPointsData`))
    const { isReqData } = couponOrPointsData || {}
    const isClickCoupon = getLocalStorage(`${member_id}_couponHit`) ? true : false
    const isClickPoints = getLocalStorage(`${member_id}_pointsHit`) ? true : false
    if (isReqData) {
      handleEventEmits({ ...couponOrPointsData, isClickCoupon, isClickPoints })
      return false
    }
    let expirePointTotal = pointsNum.value || 0
    let expireCouponTotal = couponNum.value || 0
    let pointsOrCouponInfo = { pointsNum: expirePointTotal, couponNum: expireCouponTotal }
    setLocalStorage({
      key: `${member_id}_${SiteUID}_${lang}_couponOrPointsData`,
      value: JSON.stringify({ ...couponOrPointsData, ...pointsOrCouponInfo, isReqData: 1, isClickCoupon, isClickPoints }),
      expire: 60 * 5 * 1000,
    })
    handleEventEmits({ ...pointsOrCouponInfo, isReqData: 1, isClickCoupon, isClickPoints })
  }

  const pointsOrCouponJumpEvt = async (id, type, isManualCall) => {
    if (['coupon', 'points'].includes(id) && (isLogin || isManualCall)) {
      // 点击右上角下拉积分优惠券入口
      const member_id = memberId || ''
      if (id == 'coupon' && showCouponTips.value) {
        setLocalStorage({ key: `${member_id}_couponHit`, value: 1, expire: 3600 * 24 * 3 * 1000 })
        handleEventEmits({ isClickCoupon: true }, true)
        type && triggerNotice({
          id: 'click_coupon_reminder',
          data: {
            reminder_type: 1,
            operation: type == 1 ? 0 : 1,
          },
        })
      } else if (id == 'points' && showPointsTips.value) {
        setLocalStorage({ key: `${member_id}_pointsHit`, value: 1, expire: 3600 * 24 * 7 * 1000 })
        handleEventEmits({ isClickPoints: true }, true)
        type && triggerNotice({
          id: 'click_point_reminder',
          data: {
            reminder_type: 1,
            operation: type == 1 ? 0 : 1,
          },
        })
      }
    }
  }

  const parseCookieData = (data) => {
    let tempData = {}
    if (data) {
      try {
        tempData = JSON.parse(data) || {}
      } catch (err) {
        tempData = {}
      }
    }
    return tempData
  }

  onMounted(() => {
    fetchUserInfo()
  })


  return {
    isShowPrime,
    isPrime,
    isShowExtra,
    isShowExtraIcon,
    userNickname,
    userLevelInfo,
    showCouponTips,
    showPointsTips,
    saverBuriedInfo,
    getCouponOrPointsNotice,
    pointsOrCouponJumpEvt,
    fetchUserInfo,
  }
}
