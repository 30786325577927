import { replaceHrefSpecChar } from '@shein/common-function'
import { getSaJumpTypeNew } from '../../../common/hooks/useAnalysis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

// const appConfigs = useAppConfigs() || {}
// const { forceHttp = '', lang = '', langPath = '' } = appConfigs.$envs || {}
// const { SaPageInfo } = appConfigs.$getters || {}
/**
 * 获取CCC 的纯跳转跳转链接
 * 只会依赖传入的参数
 * @param {*} item CCC 配置的组件（细到具体的banner）的数据
 * @param {*} cateLinks CCC 特殊链接的数据
 */
export function getLink({ item = {}, cateLinks = {} }, appConfigs) {
  if(item.webClickUrl){
    // 跳转链接bff接口返回，switch case 后续删除
    return item.webClickUrl
  }
  let link = 'javascript:;'
  const itemHrefType = item.hrefType
  const itemHrefTarget = item.hrefTarget
  const { langPath = '' } = appConfigs.$envs || {}
  switch (itemHrefType) {
    // 不跳转
    case 'noJump':
      link = 'javascript:;'
      break
    // Web 链接
    case 'webLink':
      // isInternalLink
      if (item.isInternalLink == 1) {
        // 内部链接
        link = `${langPath}${itemHrefTarget}`
      } else {
        // 外部链接
        link = itemHrefTarget
      }
      break
    case 'skuList':
    case 'sku':
    case 'real':
    case 'frontCat':
    case 'itemPicking':
      {
        const attrKey = itemHrefType === 'skuList' ? 'sku' : itemHrefType
        let cateLink = cateLinks[`${attrKey}_${itemHrefTarget}`] || ''
        if (cateLink.url) cateLink = cateLink.url
        const urlLink = cateLink || item.relativeUrl
        if (urlLink) {
          link = `${langPath}${urlLink}`
        }
      }
      break
    // 新专题 （专题新CCC数据路由）
    case 'activityNew':
      link = `${langPath}/campaigns/${itemHrefTarget}`
      // 检查是否为一级路径
      //   try {
      //     const pathIdentityMapInfo = this.context?.content?.pathIdentityMapInfo || []
      //     const path = pathIdentityMapInfo.filter(i => i.identity === itemHrefTarget)[0]?.tinyUrl
      //     if (path) {
      //       link = `${langPath}/${path}`
      //     }
      //   } catch (e) {
      //     console.error(e)
      //   }
      break
    // 原专题 （专题旧CCC数据路由）
    case 'activityOld':
      link = `${langPath}/campaign/${itemHrefTarget}`
      break
    case 'flashSale': // [shein]
    case 'lightningDeal': // [rowme]
      link = `${langPath}/flash-sale.html`
      break
    // [shein]
    case 'sheinPicks':
      link = `${langPath}/shein-picks.html?hrefTarget=${itemHrefTarget}`
      break
    case 'dailyNew':
      link = `${langPath}/daily-new.html`
      break
    // 问卷调查
    case 'survey':
      link = `${langPath}/survey/${itemHrefTarget}`
      break
    // 试用中心
    case 'trial':
      {
        const urlLink = cateLinks[itemHrefType] || item.webClickUrl
        if (urlLink) {
          link = `${langPath}${urlLink}`
        }
      }
      break
    // 试用列表
    case 'lookBook':
      link = `${langPath}/shop-multiple-sets-${itemHrefTarget}.html`
      break
    // 文章页
    case 'article':
      {
        if (item.relativeUrl) {
          link = `${langPath}/${item.relativeUrl}.html`
        } else {
          const linkName = item.selectTypeName || 'article'
          link = `${langPath}/${linkName}-a-${itemHrefTarget}.html`
        }
      }
      break
    // 游戏
    case 'game':
      link = item.gameType ? `${langPath}/game/${item.gameType}/${itemHrefTarget}` : 'javascript:;'
      break
    // 第三方店铺列表 [shein]
    case 'thirdPartyStoreList':
    case 'thirdPartyStoreHome':
      {
        let storeLink = cateLinks[`store_${itemHrefTarget}`] || item.webClickUrl
        if (storeLink) {
          link = `${langPath}${storeLink}`
        }
      }
      break
    // 设计师招募页 [shein]
    case 'sheinxDesignerRecruit':
      link = `${langPath}/sheinx`
      break
    // 设计师详情页 [shein]
    case 'sheinxDesignerInformation':
      link = `${langPath}/designer/${itemHrefTarget}`
      break
    // [romwe] 折扣列表
    case 'promoDiscount':
      link = `${langPath}/promo-discount.html?hrefTarget=${itemHrefTarget}`
      break
    // 礼品卡
    case 'giftCard':
      link = '/giftcard.html'
      break
    // 付费会员落地页
    case 'prime':
      link = '/user/prime'
      break
    // 一口价落地页
    case 'underPrice':
      link = `${langPath}/under-prices-products.html?tab=${itemHrefTarget}`
      break
    // 设计师列表页
    case 'sheinxDesignerList':
      link = `${langPath}/designer_list`
      break
    // LoverRomwe
    case 'loveromwe':
      link = '/looks-we-love-1.html'
      break
    // 优惠券中心
    case 'couponCenter':
      link = '/user/coupon'
      break
    // 个人中心 - 积分
    case 'pointsCenter':
      link = '/user/points'
      break
    // 个人信息编辑页
    case 'userInfoEdit':
      link = '/user/account_setting'
      break
    // 不贵频道页面
    case 'thriftyFind':
      link = '/super-deals'
      break
    case 'trendsChannel':
      link = '/top-trend'
      break
    default:
      break
  }
  return link || 'javascript:;'
}


export const getLinkAnalysisParams = ({ cate, firstCateIndex, secondCateIndex, menuIndex, dirIndex }, appConfigs) => {
  const { lang = '' } = appConfigs.$envs || {}
  const txt = cate?.navType == 'img' ? ['tab', 'navbar', 'banner'] : ['tab', 'navbar', 'menu', 'dir']
  const iciNavInfo = [firstCateIndex, secondCateIndex, menuIndex, dirIndex]
    .filter(i => i !== undefined)
    .map((index, i) => {
      return `${txt[i]}${index < 9 ? '0' + (index + 1) : index + 1}`
    })
    .join('')

  // 1. ici
  const ici = `${['en', 'enes', 'enpt-pt'].includes(lang) ? 'www' : lang}_${iciNavInfo}`

  const saContent = getSaJumpTypeNew(cate.categoryType)

  return {
    ici,
    saContent,
    ps: iciNavInfo,
    adp: cate.adp,
  }
}

export const getOptimizeAnalysisParams = ({
  data,
  firstCateIndex = '',
  secondCateName = '',
  secondCateIndex = '',
  thirdCateName = '',
  thirdCateIndex,
  forthCateName = '',
  forthCateIndex,
  currentOneCategory = 'All',
}, appConfigs) => {
  const { ici, ps, saContent } = getLinkAnalysisParams({
    cate: data,
    firstCateIndex,
    secondCateIndex,
    menuIndex: thirdCateIndex,
    dirIndex: forthCateIndex,
  }, appConfigs)

  const { SaPageInfo } = appConfigs.$getters || {}
  const src_tab_page_id = SaPageInfo ? SaPageInfo.page_name + SaPageInfo.start_time : ''
  // fc={$.一级坑位内容}`sc={$.二级坑位内容}`tc={$.三级坑位内容}`oc={$.四级坑位内容}`ps={$.资源位位置}`jc={$.跳转参数
  const src_identifier = encodeURIComponent(`fc=${currentOneCategory}\`sc=${secondCateName}\`tc=${thirdCateName || 0}\`oc=${ forthCateName || 0}\`ps=${ps}\`jc=${encodeURIComponent(saContent)}`)

  // 置顶参数
  const adp = data.autoRecommend ? data.cover?.goodsId : data.categoryType?.adp
  return {
    ici,
    ps,
    src_tab_page_id,
    src_identifier,
    adp: adp || '',
  }
}

export function getContentUrl(saInfo, isAllHover){
  let { src_identifier,url, ici } = saInfo
  src_identifier = decodeURIComponent(src_identifier || '')

  if(isAllHover){
    const arr = src_identifier.split('`')
    arr.splice(0,1,'fc=All')
    const ps = arr[4] || ''
    arr[4] = ps.replace(/tab\d+/, 'tab00')
    ici = ici.replace(/tab\d+/, 'tab00')
    src_identifier = arr.join('`')
  }

  const linkParams = [
    `ici=${ici}`,
    'src_module=topcat',
    // `src_tab_page_id=${saInfo.src_tab_page_id}`,
    `src_identifier=${src_identifier}`,
    `adp=${saInfo.adp}`,
  ]
  const second_content_list = url?.includes('?') ? `${url}&${linkParams.join('&')}` : `${url}?${linkParams.join('&')}`
  return {second_content_list, src_identifier}
}

export const getOptimizeLink = ({
  data,
  firstCateIndex = '',
  secondCateName = '',
  secondCateIndex = '',
  thirdCateName = '',
  thirdCateIndex,
  forthCateName = '',
  forthCateIndex,
  currentOneCategory = '',
  cateLinks = {},
}) => {
  const appConfigs = useAppConfigs() || {}
  let url = ''
  const {hrefType = '' , isInternalLink = 0, webClickUrl = '', relativeUrl = '' } = data?.categoryType || {}
  const orignUrl = webClickUrl || relativeUrl
  if(orignUrl){
    if(hrefType === 'webLink' && isInternalLink == 0){
      url = orignUrl
    } else {
      const { forceHttp = '' } = appConfigs.$envs || {}
      url = `${forceHttp}${orignUrl.indexOf('/') === 0 ? '':'/'}${orignUrl}`
    }
    // console.log('getlink', data.categoryType,orignUrl,url, orignUrl === url);
  }
  // else{
  //   url = getLink({ item: data.categoryType, cateLinks }, appConfigs)
  // }
  const saParams = getOptimizeAnalysisParams({
    data,
    firstCateIndex,
    currentOneCategory,
    secondCateName,
    secondCateIndex,
    thirdCateName,
    thirdCateIndex,
    forthCateName,
    forthCateIndex,
  }, appConfigs)
  if (url.startsWith('javascript:;')) return Object.assign(saParams,{url})
  url = replaceHrefSpecChar({ href: url })
  return Object.assign(saParams,{ url })
}
