import { getLocalStorage, removeLocalStorage, removeSessionStorage } from '@shein/common-function'
import { logoutHandlePersonalizedSwitch } from './utils.js'
import { windowCacheDeleteCb } from '@shein/common-function'

const USERINFO_KEY = 'auth_member'

export const removeServiceWorkerCache = () => {
  try {
    windowCacheDeleteCb({ keys: ['pcMeHtml', 'homePageDynamicData'] })
  } catch (error) {
    console.log('removeServiceWorkerCache error' + error)
  }
}

export const removeAuthUserInfo = () => {
  try {
    if (getLocalStorage(USERINFO_KEY)) {
      removeLocalStorage(USERINFO_KEY)
    }
    // 清空ugid, 防止二次落地获取的数据为登录的数据
    removeSessionStorage('ugid')

    // 退出时清理首页和个人中心的sw缓存
    removeServiceWorkerCache()

    // 个性化开关处理
    logoutHandlePersonalizedSwitch()
  } catch (error) {
    console.log( error + 'removeAuthUserInfo error')
  }
}
