<template>
  <div class="bsc-user-container">
    <a
      :href="`${host}/user/auth/login?direction=nav`"
      class="j-ipad-prevent-a j-header-username-icon sa_account"
      style="display:inline-block"
      aria-expanded="false"
      role="listbox"
      @mouseenter="showUserDropDown"
      @mouseleave="hideUserDropDown"
      @click="handleUserIconClick"
    >
      <span style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 24px;">
        <svg
          style="width: auto; height: 1em;"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0001 2.5C14.7615 2.5 17.0001 4.73858 17.0001 7.5C17.0001 10.1888 14.8777 12.3818 12.2169 12.4954L12.0001 12.5C9.23863 12.5 7.00006 10.2614 7.00006 7.5C7.00006 4.81125 9.12236 2.61818 11.7832 2.50462L12.0001 2.5ZM21.5022 21.4996C20.7234 16.957 16.766 13.5 12.0011 13.5L11.7218 13.504C7.08233 13.636 3.26361 17.046 2.50012 21.4996H21.5022ZM5.38579 19.3125C6.71588 17.0185 9.19999 15.5 12.0011 15.5L12.2549 15.5042C14.9518 15.5927 17.3267 17.088 18.6165 19.3125L18.7211 19.499H5.28106L5.38579 19.3125ZM9.00006 7.5C9.00006 5.84315 10.3432 4.5 12.0001 4.5C13.6569 4.5 15.0001 5.84315 15.0001 7.5C15.0001 9.15685 13.6569 10.5 12.0001 10.5C10.3432 10.5 9.00006 9.15685 9.00006 7.5Z"
            fill="#666666"
          />
        </svg>
      </span>
      <!-- 红点 -->
      <span
        v-if="isShowRedPoint"
        class="account-nopenum">
      </span>
    </a>
    <div
      v-show="isShowUserDropDown"
      class="bsc-user-container__dropdown bsc-header-dropdown"
    >
      <DropDown
        ref="userDropdownRef"
        :accountManager="accountManager"
        :isMsgActivityAndPromoOnly="isMsgActivityAndPromoOnly"
        :unReadNum="unReadNum"
        :isUserIconClick="isUserIconClick"
        @showDropdown="showUserDropDown"
        @hideDropdown="hideUserDropDown"
        @changeCouponOrPointsData="handleCouponOrPointsData"
        @changeActivityAndPromoOnly="handleActivityAndPromoOnly"
        @changeUnreadNum="handleUnreadNum"
        @initCouponOrPointsNoticeDone="handleInitCouponOrPointsNoticeDone"
      />
    </div>
  </div>
</template>

<script name="BPersonalCenter" setup lang="ts">
import { provide, ref, computed } from 'vue'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_PersonalCenter, DS_PersonalCenter } from '../../types'
import { useAnalysis } from '../../common/analysisSource'

import DropDown from './components/Dropdown.vue'

const appConfigs = useAppConfigs()
const { host } = appConfigs.$envs || {}
const props = defineProps<{
  fsData?: DS_PersonalCenter.FS_DATA
  dataSource?: DS_PersonalCenter.DataSource
  analysisSource?: AS_PersonalCenter.AnalysisSource
  accountManager?: string
  couponOrPointsData?: object
  isMsgActivityAndPromoOnly?: boolean
  unReadNum?: number
}>()

const emits = defineEmits([
  'changeActivityAndPromoOnly',
  'changeCouponOrPointsData',
  'initCouponOrPointsNoticeDone',
  'changeUnreadNum',
])


const apis = getSource({ appConfigs, dataSource: props.dataSource })

const { triggerNotice } = useAnalysis(props.analysisSource)

provide('apis', apis)
provide('envs', appConfigs.$envs)
provide('getters', appConfigs.$getters)
provide('triggerNotice', triggerNotice)
provide('getCouponOrPointsData', () => props.couponOrPointsData)
// 数据源：END

const isShowUserDropDown = ref(false)
const userDropdownRef = ref<any>(null)
const isUserIconClick = ref(false)

const isShowRedPoint = computed(() => {
  return userDropdownRef.value?.showRedPoint
})

const handleUserIconClick = () => {
  isUserIconClick.value = true
}

const showUserDropDown = () => {
  isShowUserDropDown.value = true
}

const hideUserDropDown = () => {
  isShowUserDropDown.value = false
}

const handleCouponOrPointsData = (data) => {
  emits('changeCouponOrPointsData', data)
}

const handleActivityAndPromoOnly = (data) => {
  emits('changeActivityAndPromoOnly', data)
}

const handleUnreadNum = (data) => {
  emits('changeUnreadNum', data)
}

const handleInitCouponOrPointsNoticeDone = () => {
  emits('initCouponOrPointsNoticeDone')
}
</script>

<style lang="less">
.bsc-user-container {
  position: relative;
  >a {
    position: relative;
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    left: -8px;
    padding: 0 20px;
    z-index: 600;
    background-color: #fff;
    padding-right: 20px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s;
    min-width:168px;
  }
  .account-nopenum {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f86262;
  }
}
</style>
