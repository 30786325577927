import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BCategory } from './category.vue'
import type { DS_Category } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getCategoryFsDataRunner(sourceParams?: Partial<DS_Category.FS_SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
